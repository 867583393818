import { Box, Button, FormControl, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { WorkHistoryItem } from "../../types/resume/Resume";

export type WorkHistoryItemEditProps = {
    workHistoryItem: WorkHistoryItem,
    onItemChanged: (item: WorkHistoryItem) => void
}

const WorkHistoryItemEdit = (props: WorkHistoryItemEditProps) => {
    const [whItem, setWhItem] = useState<WorkHistoryItem>();
    const [addResponibility, setAddResponibility] = useState('');

    useEffect(() => {
        setWhItem(props.workHistoryItem);
    }, [props.workHistoryItem]);

    const handleTitleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (whItem !== undefined) {
            const nw: WorkHistoryItem = { ...whItem, title: e.target.value };
            props.onItemChanged(nw);
            setWhItem(nw);
        }        
    };

    const handleFromChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (whItem !== undefined) {
            const nw: WorkHistoryItem = { ...whItem, from: e.target.value };
            props.onItemChanged(nw);
            setWhItem(nw);
        }        
    };

    const handleToChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (whItem !== undefined) {
            const nw: WorkHistoryItem = { ...whItem, to: e.target.value };
            props.onItemChanged(nw);
            setWhItem(nw);
        }
    };

    const handleResponsibilityChanged = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
        if (whItem?.responsibilities !== undefined) {
            const nw: WorkHistoryItem = {
                ...whItem,
                responsibilities: whItem.responsibilities.map((r, ri) => {
                    var returnValue = r;
                
                    if (ri === i) {
                        returnValue = e.target.value;
                    }
                
                    return returnValue;

                })
            };

            props.onItemChanged(nw);
            setWhItem(nw);
        }
    };

    const handleResponsibilityDeleted = (i: number, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        if (whItem?.responsibilities !== undefined) {
            const nw: WorkHistoryItem = {
                ...whItem,
                responsibilities: whItem.responsibilities.filter((r, index) => index !== i)
            };

            props.onItemChanged(nw);
            setWhItem(nw);
        }
    };

    const handleResponsibilityAdded = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        if (whItem?.responsibilities !== undefined) {
            const nw: WorkHistoryItem = {
                ...whItem,
                responsibilities: [...whItem?.responsibilities, addResponibility]
            };

            setAddResponibility('');
            props.onItemChanged(nw);
            setWhItem(nw);
        }
    };
    
    return (
        <Box sx={{ padding: '24px', marginTop: '24px', borderTop: '2px solid #ddd' }}>
            <Typography variant="h6">{whItem?.company}</Typography>
            <Stack direction="row" sx={{ padding: '18px 0 18px 0' }}>
                <Box>
                    <FormControl>
                        <Typography variant="body1">Title</Typography>
                        <TextField
                            id="title"
                            variant="outlined"
                            size="small"
                            onChange={handleTitleChanged}
                            value={whItem?.title}
                            sx = {{ width: '240px' }}
                        ></TextField>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <Typography variant="body1">From</Typography>
                        <TextField
                            id="from"
                            variant="outlined"
                            size="small"
                            onChange={handleFromChanged}
                            value={whItem?.from}
                            sx = {{ width: '160px' }}
                        ></TextField>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <Typography variant="body1">To</Typography>
                        <TextField
                            id="to"
                            variant="outlined"
                            size="small"
                            onChange={handleToChanged}
                            value={whItem?.to}
                            sx = {{ width: '160px' }}
                        ></TextField>
                    </FormControl>
                </Box>
            </Stack>
            <Typography variant="body1">Responsibilities</Typography>
            <Stack direction="column">
                {whItem?.responsibilities?.map((r, i) => {
                    return (
                        <Box>
                            <Button 
                                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleResponsibilityDeleted(i, e)}
                                variant="contained"                                                                
                                href="#"
                                color="error"
                                sx={{ width: '120px' }}
                            >
                                Delete
                            </Button>
                            <FormControl>
                                <TextField
                                    id={`responsibility-delete-${i}`}
                                    variant="outlined"
                                    size="small"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleResponsibilityChanged(i, e)}
                                    value={r}
                                    sx = {{ width: '800px' }}
                                />
                            </FormControl>
                        </Box>
                    )
                })}
                <Box>
                    <Button 
                        onClick={handleResponsibilityAdded}
                        variant="contained"
                        href="#"
                        color="success"
                        sx={{ width: '120px' }}
                    >
                        Add
                    </Button>
                    <FormControl>
                        <TextField
                            id="responsibility-add-text"
                            variant="outlined"
                            size="small"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAddResponibility(e.target.value)}
                            value={addResponibility}
                            sx = {{ width: '800px' }}
                        />
                    </FormControl>
                </Box>
            </Stack>
        </Box>
    );
}

export default WorkHistoryItemEdit;
