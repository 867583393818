import { create } from "zustand";

export interface AppState {
    isAdmin: boolean;
    setAdminOn: () => void;
    setAdminOff: () => void;
}
  
const useAppStore = create<AppState>((set) => ({
    isAdmin: false,
    setAdminOn: () => set(state => ({ isAdmin: true })),
    setAdminOff: () => set(state => ({ isAdmin: false }),
)}));

export default useAppStore;
;
