import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const theme = createTheme({
  status: {
    danger: red[500],    
  },    
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    mode: 'light',
    background: {
      default: "#2e3641"
    },
    primary: {
      main: '#E33E7F'
    },
    secondary: {
      main: '#cd7871'
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#2e3641"
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        
      }
    }
  }
});

export default theme;