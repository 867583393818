import { AppBar, Box, Container, Link, Stack, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import logo from '../../assets/DaveKjaer.png';
import useAppStore from "../store/store";
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from "../../theme";

const NavBar = () => {
  const { isAdmin, setAdminOn } = useAppStore();
  const menustyle = { padding: '0 24px 0 0' };  
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  
  return (
    <AppBar position="static" >
      <Container maxWidth="lg">
        <Toolbar>
          <Stack direction={{ md: 'column', lg: 'row' }}>
            <Box>
              <Typography variant="body1" component="div" sx={menustyle}>
                <Link component={RouterLink} to="/" color="inherit" underline="none">
                  Home
                </Link>
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" component="div" sx={menustyle}>
                <Link component={RouterLink} to="/resume" color="inherit" underline="none">
                Resume
                </Link>
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" component="div" sx={menustyle}>
                <Link component={RouterLink} to="/concerts" color="inherit" underline="none">
                Concerts
                </Link>
              <button onClick={setAdminOn} style={{ backgroundColor: '#2e3641', border: 0 }}>
                &nbsp; &nbsp;
              </button>
              </Typography>
            </Box>
            {isAdmin && (
              <Box>
                <Typography variant="body1" component="div" sx={menustyle}>
                  <Link component={RouterLink} to="/admin" color="inherit" underline="none">
                  Admin
                  </Link>
                </Typography>
              </Box>
              )
            }
          </Stack>
          <Stack alignContent="right" direction="row" sx={{ marginLeft: 'auto' }}>
          {matches && <Box component="img" sx={{ height: 45, width: 140, float: 'right' }} alt="Dave Kjaer" src={logo} />}
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;