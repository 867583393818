import { Box, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useState, CSSProperties, useContext } from 'react';
import { Concert } from '../../types/Concert';
import { getConcerts } from '../../components/apis/davekjaer-api';
import { formatShowDate } from '../../components/helpers/dateHelpers';
import PulseLoader from "react-spinners/PulseLoader";
import { GlobalContext } from '../../components/globalContext/globalContext';

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
};

const ConcertsScreen = () => {
    const [loading, setLoading] = useState(true);
    const [concerts, setConcerts] = useState<Concert[]>();
    const globalContext = useContext(GlobalContext);

    useEffect(() => {
        getConcerts().then(c => {
            setConcerts(c);
        });
    }, []);

    useEffect(() => {
        if (concerts !== undefined) {
            setLoading(false);
        }
    }, [concerts]);
    
    return (
        <Stack>
            <Box>
                <Typography variant="h5">Every Concert I Have Seen</Typography>
                <Typography variant='body2'>This is a list of the live shows that I have attended. I started this list in the late nineties so everything before that is from memory and ticket stubs.</Typography>
                <Typography variant='body2'>The lineups are arranged so the headliner appears at the top and the opener is at the bottom. Shows with unknown dates are listed in a separate list at the bottom.</Typography>
            </Box>
            {concerts?.map((c) => {
                return (
                    <Paper sx={{ padding: '24px', margin: '18px 6px 16px 6px', backgroundColor: '#f9f9f9', border: '1px solid #ddd'}} elevation={0}>
                        <Stack  sx={{ flexDirection: { xs: "column", md: "row" }}}>
                            <Box sx={{ width: { xs: 1, md: 2/6 }, textAlign: 'left', color: '#77d', fontWeight: 'bold' }}>{c.venue?.name}</Box>
                            <Box sx={{ width: { xs: 1, md: 3/6 }}}></Box>
                            <Box sx={{ width: { xs: 1, md: 1/6 }, textAlign: { xs: 'left', md: 'right' }, color: '#77d' }}>{c.showDate ? formatShowDate(c.showDate) : 'unknown'}</Box>
                        </Stack>
                        <Stack sx={{ flexDirection: { xs: "column", md: "row" }}}>
                            <Box sx={{ width: { xs: 1, md: 2/6 }, textAlign: 'left', paddingLeft: '20px', paddingTop: '12px' }}>
                                <Stack>
                                    {c.artists.map(a => {
                                        return (
                                            <Box sx={{ fontWeight: 'bold', color: '#444' }}>
                                                {a}
                                            </Box>
                                        )
                                    })}
                                </Stack>
                            </Box>
                            <Box sx={{ width: { xs: 1, md: 3/6 } }}></Box>
                            <Box sx={{ width: { xs: 1, md: 1/6 }, textAlign: 'right', color: '#0a0' }}>{c.price ? `${c.price.toLocaleString('en-US', { style: 'currency', currency: 'USD'})}` : ''}</Box>
                        </Stack>
                        {c.notes &&
                        <Box sx={{ paddingTop: '24px', marginTop: '24px', borderTop: '1px solid #ddd', color: '#8f8f8f' }}>
                            {c.notes}
                        </Box>}
                    </Paper>
                )
            })}
            <PulseLoader
                loading={loading}
                cssOverride={override}
                size={15}
                color={globalContext?.spinnerColor}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </Stack>
    );
}

export default ConcertsScreen;