
export const formatDate = (date: string | number | Date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }

    if (day.length < 2) {
        day = '0' + day;
    }

    return [year, month, day].join('-');
}

export const formatShowDate = (date: string | number | Date) => {
    let d = new Date(date);
    d.setDate(d.getDate() + 1); // Need to add a day for some odd reason.
    return d.toLocaleDateString('en-US', { year: 'numeric', day: '2-digit', month: 'short' });
}