import { Box, Typography } from '@mui/material';
import React from 'react';
import { EducationItem } from '../../types/resume/Resume';

export type ResumeEducationItemProps = {
    educationItem: EducationItem,
}

const ResumeEducationItem = (props: ResumeEducationItemProps) => {
    return (
        <Box sx={{ paddingBottom: '18px' }}>
            <Typography variant="h6">{props.educationItem?.degree}</Typography>
            <Typography variant="body1">{props.educationItem?.school}</Typography>
            <Typography variant="body2" sx={{ color: '#888' }}>{props.educationItem?.from} - {props.educationItem?.to}</Typography>
            <Typography variant='body2'>{props.educationItem?.description}</Typography>
        </Box>
    );
}

export default ResumeEducationItem;