import React from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Home from './areas/Home/Home';
import NoMatch from './areas/NoMatch/NoMatch';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Admin from './areas/Admin/admin';
import { ThemeProvider } from "@mui/material/styles";
import theme from './theme';
import { Container, CssBaseline, Paper } from '@mui/material';
import ConcertsScreen from './areas/Concerts/ConcertsScreen';
import NavBar from './components/nav-bar/NavBar';
import ResumeScreen from './areas/Resume/ResumeScreen';
import WorkHistoryEdit from './areas/Resume/WorkHistoryEdit';
import SkillsEdit from './areas/Resume/SkillsEdit';
import { GlobalProvider } from './components/globalContext/globalContext';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }

  // allow configuration using `createTheme()`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const App = () => {  
  const queryClient = new QueryClient();
  
  return (
    <ThemeProvider theme={theme}>
      <GlobalProvider>
        <CssBaseline />
        <div className="App">
          <QueryClientProvider client={queryClient}>
            <NavBar />
            <Container>
              <div style={{ height: '20px' }}></div>
              <Paper sx={{ padding: { xs: '24px', md: '64px' }, minHeight: '600px', marginBottom: '24px' }}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/resume" element={<ResumeScreen />} />
                  <Route path="/concerts" element={<ConcertsScreen />} />
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/whedit" element={<WorkHistoryEdit />} />
                  <Route path="/skilledit" element={<SkillsEdit />} />
                  <Route path="*" element={<NoMatch />} />
                </Routes>
              </Paper>
            </Container>
          </QueryClientProvider>
        </div>
      </GlobalProvider>
    </ThemeProvider>
  );
}

export default App;
