import React from 'react';
import { WorkHistoryItem } from '../../types/resume/Resume';
import { Box, List, ListItem, Typography } from '@mui/material';

export type ResumeWorkHistoryItemProps = {
    workHistoryItem: WorkHistoryItem,
}

const ResumeWorkHistoryItem = (props: ResumeWorkHistoryItemProps) => {
    return (
        <Box sx={{ paddingBottom: '12px' }}>
            <Typography variant="h6">{props.workHistoryItem.title}</Typography>
            <Typography variant="body1">{props.workHistoryItem.company}</Typography>
            <Typography variant="body2" sx={{ color: '#888' }}>{props.workHistoryItem.from} - {props.workHistoryItem.to}</Typography>            
            <List sx={{ listStyleType: 'disc' }}>
                {props.workHistoryItem.responsibilities?.map((r) => {
                    return (
                        <ListItem sx={{ display: 'list-item' }}>
                            <Typography variant='body2'>{r}</Typography>
                        </ListItem>
                    )}
                )}
            </List>
        </Box>
    );
}

export default ResumeWorkHistoryItem;