import React, { createContext, useState } from 'react';

interface IGlobalContext {
    spinnerColor: string;
    count: number;
    incrementCount: () => void,
}

const defaultValues: IGlobalContext = {
    spinnerColor: "#2e3641",
    count: 0,
    incrementCount: () => {},
};

type GlobalProviderProps = {
  children: React.ReactNode;
};

export const GlobalContext = createContext<IGlobalContext | undefined>(undefined);

export const GlobalProvider = ({ children }: GlobalProviderProps) => {
  const [count, setCount] = useState(defaultValues.count);
  const spinnerColor = defaultValues.spinnerColor;

  const incrementCount = () => {
    setCount(count);
  };

  return (
    <GlobalContext.Provider value={{ spinnerColor, count, incrementCount }}>
      {children}
    </GlobalContext.Provider>
  );
};
