import { Box, Typography } from '@mui/material';
import './SkillItem.scss';
import React from 'react';
import { Skill } from '../../types/resume/Resume';

export type SkillItemProps = {
    skill: Skill,
}

const SkillItem = (props: SkillItemProps) => {
    const widthString = `${props.skill?.percentage}%`;

    return (
        <Box sx={{ margin: '12px 0 6px 0' }}>
            <Box sx={{ marginBottom: '8px' }}>
            <Typography variant="h6">{props.skill?.title}</Typography>
            <Typography variant="body2">
                {props.skill?.description}
            </Typography>
            </Box>
            <Box sx={{
                color: '#111111',
                backgroundColor: '#eee',
                width: '100%',
                height: '25px',
                borderRadius: '3px',
            }}>
                <Box className="animate" sx={{ '--percentage': widthString, background: props.skill?.color, height: '25px', paddingLeft: '12px', width: widthString, borderRadius: '3px' }}>                        
                    <Typography sx={{ fontSize: '.9em', fontWeight: 700 }}>{props.skill?.percentage}%</Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default SkillItem;