import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import WorkHistoryItemEdit from "./WorkHistoryItemEdit";
import { Resume, WorkHistoryItem } from "../../types/resume/Resume";
import { getResume, postResume } from "../../components/apis/davekjaer-api";

const WorkHistoryEdit = () => {
    const [resume, setResume] = useState<Resume>();

    useEffect(() => {
        getResume().then(r => {
            setResume(r);
        });
    }, []);

    const handleItemChanged = (item: WorkHistoryItem) => {
        if (resume !== undefined) {
            const oResume = {
                ...resume,
                workHistory: resume?.workHistory.map((w) => {
                    var returnValue = {...w};                
                    if (w.company === item.company) {
                        returnValue = item;
                    }
                
                    return returnValue;
                })
            }

            setResume(oResume);
        }
    }
        
    const handleResumeSave = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        if (resume !== undefined) {
            postResume(resume).then(b => {
                setTimeout(() => getResume().then((r) => setResume(r)), 1000);
            });
        }
    }

    return (
        <Paper>            
            <Box sx={{ padding: '48px' }}>
                <Typography variant="h5">Work History</Typography>
                <Stack>
                    {resume?.workHistory?.map((w) => <WorkHistoryItemEdit workHistoryItem={w} onItemChanged={handleItemChanged} />)}
                </Stack>
                <Box sx={{ padding: '24px', marginTop: '24px', borderTop: '2px solid #ddd' }}>
                    <Button 
                        onClick={handleResumeSave}
                        variant="contained"
                        href="#"
                        color="success"
                        sx={{ width: '180px' }}
                    >
                        Save Resume
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
}

export default WorkHistoryEdit;
