
import { Box, Button, FormControl, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Skill } from "../../types/resume/Resume";
import { Chrome, ColorResult } from '@uiw/react-color';


export type SkillItemEditProps = {
    index: number,
    skill: Skill,
    onItemChanged: (index: number, item: Skill) => void,
    onItemDeleted: (index: number) => void,
}

const SkillItemEdit = (props: SkillItemEditProps) => {
    const [skill, setSkill] = useState<Skill>();

    useEffect(() => {
        setSkill(props.skill);
    }, [props.skill]);

    const handleTitleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (skill !== undefined) {
            const ns: Skill = { ...skill, title: e.target.value };
            props.onItemChanged(props.index, ns);
            setSkill(ns);
        }        
    };

    const handleDescriptionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (skill !== undefined) {
            const ns: Skill = { ...skill, description: e.target.value };
            props.onItemChanged(props.index, ns);
            setSkill(ns);
        }        
    };

    const handlePercentageChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (skill !== undefined) {
            const ns: Skill = { ...skill, percentage: parseInt(e.target.value) };
            props.onItemChanged(props.index, ns);
            setSkill(ns);
        }        
    };

    const handleColorChanged = (e: ColorResult) => {
        if (skill !== undefined) {
            const ns: Skill = { ...skill, color: e.hex };
            props.onItemChanged(props.index, ns);
            setSkill(ns);
        }        
    };

    const handleSkillDeleted = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        if (skill !== undefined) {
            props.onItemDeleted(props.index);
        }        
    };
    
    return (
        <Box sx={{ padding: '24px', marginTop: '24px', borderTop: '2px solid #ddd' }}>
            <Stack direction="row" sx={{ padding: '18px 0 18px 0' }}>
                <Box>
                    <FormControl>
                        <Typography variant="body1">Title</Typography>
                        <TextField
                            id="title"
                            variant="outlined"
                            size="small"
                            onChange={handleTitleChanged}
                            value={skill?.title}
                            sx = {{ width: '160px' }}
                        ></TextField>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <Typography variant="body1">Description</Typography>
                        <TextField
                            id="from"
                            variant="outlined"
                            size="small"
                            onChange={handleDescriptionChanged}
                            value={skill?.description}
                            sx = {{ width: '360px' }}
                        ></TextField>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <Typography variant="body1">Percentage</Typography>
                        <TextField
                            id="to"
                            variant="outlined"
                            size="small"
                            onChange={handlePercentageChanged}
                            value={skill?.percentage}
                            sx = {{ width: '120px' }}
                        ></TextField>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <Typography variant="body1">Color</Typography>
                        <Chrome 
                            style={{ marginLeft: 20 }}
                            color={skill?.color}
                            onChange={handleColorChanged}
                        />
                    </FormControl>
                </Box>
            </Stack>
            <Stack>
                <Button 
                    onClick={handleSkillDeleted}
                    variant="contained"
                    href="#"
                    color="error"
                    sx={{ width: '120px' }}
                >
                    Delete
                </Button>
            </Stack>
        </Box>
    );
}

export default SkillItemEdit;
