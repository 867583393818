import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState, CSSProperties, useContext } from "react";
import { Resume } from "../../types/resume/Resume";
import { getResume } from "../../components/apis/davekjaer-api";
import ResumeWorkHistoryItem from "./ResumeWorkHistoryItem";
import ResumeEducationItem from "./ResumeEducationItem";
import SkillItem from "./SkillItem";
import useAppStore from "../../components/store/store";
import { Link } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import { GlobalContext } from '../../components/globalContext/globalContext';

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
};

const ResumeScreen = () => {
    const { isAdmin } = useAppStore();
    const [loading, setLoading] = useState(true);
    const [resume, setResume ] = useState<Resume>();
    const globalContext = useContext(GlobalContext);
    const spinnerSize = 12;

    useEffect(() => {
        const callGetResume = async () => {
            const r = await getResume();
            setResume(r);
        }

        callGetResume();
    }, []);
    
    useEffect(() => {
        if (resume !== undefined) {            
            setLoading(false);
        }
    }, [resume]);

    return (
        <>
            <Stack spacing={3}>
                {isAdmin &&            
                    <Stack spacing={1}>
                        <Link to="/whedit"><Button color="warning" variant="contained">Edit Work History</Button></Link>
                        <Link to="/skilledit"><Button color="warning" variant="contained">Edit Skills</Button></Link>
                    </Stack>
                }
                <Box sx={{ textAlign: 'center', padding: '0px 36px 12px 36px' }}>
                    <Typography variant="h4">Dave Kjaer</Typography>
                    <Typography variant="h6">{resume?.title}</Typography>
                </Box>
                <Box>
                    <Typography variant="body2">{resume?.objective}</Typography>
                </Box>
            </Stack>
            <Stack sx={{ paddingLeft: { xs: '12px', md: '36px' }, paddingRight: '36px', flexDirection: { xs: "column", md: "row" }}} spacing={3}>
                <Box sx={{ width: { xs: 1, md: 2/3 } }}>
                    <Stack spacing={1} sx={{ paddingTop: '18px' }}>
                        <Box sx={{ paddingLeft: { xs: '1px', md: '12px' } }}>
                            <Typography variant="h5" sx={{ color: '#aaa', paddingBottom: '12px' }}>Work History</Typography>
                            {resume?.workHistory?.map((wh) => <ResumeWorkHistoryItem workHistoryItem={wh} />)}
                            <PulseLoader
                                loading={loading}
                                cssOverride={override}
                                size={spinnerSize}
                                color={globalContext?.spinnerColor}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </Box>
                        <Box sx={{ paddingLeft: { xs: '1px', md: '12px' } }}>
                            <Typography variant="h5" sx={{ color: '#aaa', paddingBottom: '12px' }}>Education</Typography>
                            {resume?.education?.map((e) => <ResumeEducationItem educationItem={e} />)}
                            <PulseLoader
                                loading={loading}
                                cssOverride={override}
                                size={spinnerSize}
                                color='#2e3641'
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{ width: { xs: 1, md: 1/3 } }}>
                    <Typography variant="h5" sx={{ color: '#aaa' }}>Skills</Typography>
                    {resume?.skills?.map((s) => <SkillItem skill={s} />)}
                    <PulseLoader
                        loading={loading}
                        cssOverride={override}
                        size={spinnerSize}
                        color='#2e3641'
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </Box>
            </Stack>
        </>
    );
}

export default ResumeScreen;