import React from 'react';
import { Box, Link, Stack, Typography } from '@mui/material';
import cycling from '../../assets/Cycling.jpg';
import certBadge from '../../assets/mongodb-associate-developer.5.png';
import iconFacebook from '../../assets/icons8-facebook-96.png';
import iconGithub from '../../assets/icons8-github-96.png';
import iconSpotify from '../../assets/icons8-spotify-96.png';
import iconLinkedIn from '../../assets/icons8-linkedin-96.png';
import iconStrava from '../../assets/icons8-strava-96.png';
import iconYouTube from '../../assets/icons8-youtube-96.png';
import pwLogo from '../../assets/pace-wizard-logo-sm-text-inv.png';
import './Home.scss';

const Home = () => {
  return (
    <Stack sx={{ flexDirection: { xs: "column", md: "row" }}} spacing={3}>
      <Box sx={{ width: { xs: 1, md: 1/2 }}}>
        <Stack spacing={3}>
          <Box>
            <Typography variant='h4'>Hello, my name is Dave Kjaer…</Typography>
          </Box>
          <Box>
            <Typography variant='body1'>
              <span className="dropcap dropcap-bg">I</span> am a software developer who loves working
              with React, .Net, Kafka, MongoDB and SQL. Oh yeah, I am also a music geek, vegan, runner
              and cyclist <span style={{ color: "#999" }}>(hence, the image)</span>.
            </Typography>
          </Box>
          <Box>            
            <Box component="img" sx={{ float: 'right',  width: '144px', height: '144px' }} alt="Dave Kjaer" src={certBadge} />
            
            <Typography variant='h6'>Certified MongoDB Associate Developer</Typography>
            I recently earned the MongoDB Associate Developer certification and am pretty stoked about it. Hey, it's my website
            so I'm gonna show off the badge!!
          </Box>
          <Box>
            My latest project is a website where I use React, C# and MongoDB to build a few handy tools for runners. The most
            notable is a marathon training calendar complete with training paces! I also added some calculators for biking
            and swimming. The site is named PaceWizard and you can check it out here...
            <Box sx={{ paddingTop: '12px' }}>
              <Link href="https://www.pacewizard.com" target="_blank">
                <Box component="img" alt="LinkedIn" src={pwLogo} />
              </Link>
            </Box>
          </Box>
        </Stack>
        <Stack direction="row" sx={{ paddingTop: { xs: '12px', sm: '60px' }}}>
          <Link href="https://www.linkedin.com/in/davekjaer/" target="_blank">
            <Box component="img" sx={{ width: '48px', height: '48px' }} alt="LinkedIn" src={iconLinkedIn} />
          </Link>
          <Link href="https://www.facebook.com/davekjaer" target="_blank">
            <Box component="img" sx={{ width: '48px', height: '48px' }} alt="Facebook" src={iconFacebook} />
          </Link>
          <Link href="https://github.com/dkjaer" target="_blank">
            <Box component="img" sx={{ width: '48px', height: '48px' }} alt="Github" src={iconGithub} />
          </Link>
          <Link href="https://www.youtube.com/@dkjaer" target="_blank">
            <Box component="img" sx={{ width: '48px', height: '48px' }} alt="Strava" src={iconYouTube} />
          </Link>
          <Link href="https://open.spotify.com/user/121407210" target="_blank">
            <Box component="img" sx={{ width: '48px', height: '48px' }} alt="Spotify" src={iconSpotify} />
          </Link>
          <Link href="https://www.strava.com/athletes/1428497" target="_blank">
            <Box component="img" sx={{ width: '48px', height: '48px' }} alt="Strava" src={iconStrava} />
          </Link>
        </Stack>
      </Box>
      <Box sx={{ width: { xs: 1, md: 1/2 }}}>
        <img src={cycling} alt="Me Pixelized" style={{ float: "right", maxWidth: '100%' }} />
      </Box>
    </Stack>
  );
}

export default Home;