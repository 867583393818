import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Resume } from "../../types/resume/Resume";
import { postResume } from "../../components/apis/davekjaer-api";

const Admin = () => {
    const [resume, setResume] = useState<Resume>();

    useEffect(() => {
        setResume(buildResume());
    }, []);

    const handleUpdateResume = async () => {
        if (resume !== undefined) {
            await postResume(resume);
        }
    }

    return (
        <Paper>
            <Stack direction="row" sx={{ padding: '36px' }} spacing={3}>
            <Typography variant="h4">Administration</Typography>
            <Stack spacing={1}>
                <Box>
                    <Button onClick={handleUpdateResume}>
                        Insert Resume
                    </Button>
                </Box>
            </Stack>
            </Stack>
        </Paper>
    );
}

const buildResume = (): Resume => {
    const resume: Resume = {
        id: "678d1329cde871c5b5ebca4b",
        title: "Full Stack Developer",
        objective: "Highly-skilled, software engineer with over two decades of experience in creating innovative web and desktop applications in a wide variety of environments using a wide variety of programming languages, frameworks and databases.",
        summary: [
            "16 years of experience using C# including LINQ, Entity Framework, TPL, generics, dependency injection, RESTful services, MVC, unit testing and various architectures and design patterns",
            "20+ years of experience developing web applications using HTML, JavaScript, jQuery, Blazor, React 5, Redux, CSS, SCSS and Bootstrap",
            "20+ years of experience with databases such as MS SQL Server, Azure SQL, MySQL and Btrieve including relational database design, stored procedures, views, functions, indices and triggers",
            "5 years working with Azure development and administration including Azure SQL, Azure Functions, Durable Entities, Cosmos DB, web applications, service buses and virtual machines",
            "3 years working with Kafka including topic design and developing producers and consumers",
            "Using Python for operations such as data mining, web scraping, automated tasks, and parsing",
            "Source control systems such as Git/Bitbucket and Subversion",
            "Considerable experience using Photoshop to create appealing, web-optimized graphics and interface controls",
            "Acceptance testing, unit testing and dependency injection tools such as MSTest AND Moq",
            "Working in an Agile environment including retrospective meetings, sprints, points, and velocity",
            "DevOps tools such as Jenkins, SonarQube and Octopus Deploy and have maintained role as DevOps Advocate interfacing between developers and DevOps team",
            "Experience with network administration including firewalls, DNS, DHCP, IIS, Citrix, RDP servers, Active Directory, group policies, login scripts and user management",
            "Interfacing applications with external peripherals, sensors, servo motors, switches, counters, and PLC systems",
            "Installation, configuration and maintenance of Windows-based servers and desktops",
        ],
        workHistory: [{
            title: "Software Engineer II",
            company: "C. H. Robinson",
            from: "December 2017",
            to: "present",
            responsibilities: [
                "Develop RESTful APIs using C#.",
                "Develop Kafka producers and consumers using .Net Core.",
                "Work with Azure SQL, Azure Functions, Durable Entities, Cosmos DB",
                "Develop UI's using React and Redux.",
                "Create tables and stored procedures.",
                "Create ESB plugins.",
                "Create acceptance, E2E and unit tests using MSTest, Moq, Jest and Enzyme.",
                "Manage source code and resolving conflicting code conflicts.",
                "Working with deployment pipeline tools such as SonarQube, Jenkins and Octopus.",
                "Work on SCRUM team in an Agile environment.",
            ],
            summary: "This is a summary"
        }, {
            title: "Full Stack Developer",
            company: "Alternative Strategy Advisers",
            from: "June 2004",
            to: "December 2017",
            responsibilities: [
                "Built and maintained the company website using C#, JQuery and CSS.",
                "Created a suite of applications to handle complete end-to-end workflow of daily processes.",
                "Developed applications using C# and Python which pull in market data from a wide variety of sources.",
                "Created a client/server system and custom protocol which allow .NET applications to communicate in real-time.",
                "Created stored procedures, tables, and indices in an Azure SQL database.",
                "Transferred all in-house servers, processes and data to Azure and SharePoint.",
                "Administered cloud-based services such as SharePoint, Office 365, and Azure.",
                "Created a website using WordPress for showcasing rental property.",
                "Maintained all servers and pcs.",
            ]
        }, {
            title: "Programmer",
            company: "Winrep Software",
            from: "April 1998",
            to: "June 2004",
            responsibilities: [
                "Worked on transitioning a desktop CRM to a web-interface using ColdFusion and SQL Server. Web application was featured in the July and August 2003 editions of Agency Sales Magazine.",
                "Created stored procedures, tables, and indices in a MS SQL Server database.",
                "Maintained a desktop CRM built with Visual Basic and a Btrieve database.",
                "Developed several custom ColdFusion server add-ons using C++.",
            ]
        }, {
            title: "Electrical Controls Engineer",
            company: "Mereen-Johnson Machine Company",
            from: "1995",
            to: "April 1998",
            responsibilities: [
                "Worked as drafter/designer and was promoted to engineer position in 1995.",
                "Developed software for managing CAD drawings using VB.",
                "Designed electronic and hydraulic systems for industrial woodworking machinery.",
                "Developed the software using Visual Basic 5 for a groundbreaking pc-controlled CNC Tenoner. One of the highlights of the 1997 International Woodworking Fair in Atlanta.",
                "Programmed servo motor drive systems using C.",
                "Designed PLC systems which interfaced with various switches, sensors, and other devices.",
                "Traveled the country performing installations and maintenance on machinery in manufacturing plants.",
                "Performed presentations showcasing new technologies and machines at national trade shows.",
            ]
        }],
        education: [{
            degree: "Associate of Applied Science",
            school: "Minneapolis Drafting School",
            from: "1990",
            to: "1992",
            description: "Studied electrical/mechanical drafting and design using drawing board and AutoCAD. Graduated in 1992.",
        }, {
            degree: "Certificate of Occupational Proficiency",
            school: "Thief River Falls Area Vocational Technical Institute",
            from: "1985",
            to: "1987",
            description: "Studied electronics including semiconductor theory, digital circuit design and electronic theory. Graduated in 1987.",
        }],
        skills: [{
            title: "C#",
            description: "Object-Oriented Patterns, LINQ, Generics, asyncronous methods",
            percentage: 95,
            color: "rgb(247, 199, 56)",
        }, {
            title: "Javascript",
            description: "React, Typescript",
            percentage: 80,
            color: "rgb(174, 237, 130)",
        }, {
            title: "Kafka",
            description: "General topic design and management, producers, consumers, Confluent API",
            percentage: 80,
            color: "rgb(147, 142, 237)",
        }, {
            title: "Databases",
            description: "SQL Server/Azure and MySQL, stored procs, indices, triggers, RD design",
            percentage: 90,
            color: "rgb(234, 136, 178)",
        }, {
            title: "CSS",
            description: "Bootstrap, SASS, Responsive CSS",
            percentage: 90,
            color: "rgb(244, 113, 93)",
        }, {
            title: "Python",
            description: "Data mining, web scraping, document parsing, file handling",
            percentage: 90,
            color: "rgb(185, 197, 232)",
        }, {
            title: "Testing",
            description: "MS Test, Moq, Jest, Enzyme",
            percentage: 80,
            color: "rgb(238, 242, 29)",
        }, {
            title: "Network Administration",
            description: "Firewall configuration, DNS, DHCP, Windows AD, IIS, Citrix, RDP Server",
            percentage: 70,
            color: "rgb(221, 153, 51)",
        },
    ]};

    return resume;
}

export default Admin;