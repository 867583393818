import { Concert } from "../../types/Concert";
import { GetConcertsResponse } from "../../types/messages/GetConcertsResponse";
import { Resume } from "../../types/resume/Resume";
import { httpClient } from "./httpClient";
import { AxiosResponse } from 'axios';

export const getConcerts = async (): Promise<Concert[]> => {
    const response: AxiosResponse<GetConcertsResponse> = await httpClient.get<GetConcertsResponse>('/Music/Concerts');
    const plans = response.data;
    return plans.concerts;
}

export const getResume = async (): Promise<Resume> => {
    const response: AxiosResponse<Resume> = await httpClient.get<Resume>('/Resume');
    const resume = response.data;
    return resume;
}

export const postResume = async (resume: Resume): Promise<boolean> => {
    httpClient.post<boolean>('/Resume', resume)
        .then((response) => {
            const result = response.data;
            return result;
        })
        .catch((error) => {
            // Error
            if (error.response) {
                console.log('error.response: ', error.response);
            } else if (error.request) {
                console.log('error.request: ', error.request);
            } else {
                console.log('Error', error.message);
            }

            console.log(error.config);
        });

    return true;
}