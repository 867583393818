import React from 'react';

const NoMatch = () => {
  return (
    <div>
        Sorry, that is an unknown route!
    </div>
  );
}

export default NoMatch;