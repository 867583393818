import { Box, Button, FormControl, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Resume, Skill } from "../../types/resume/Resume";
import { getResume, postResume } from "../../components/apis/davekjaer-api";
import SkillItemEdit from "./SkillItemEdit";
import { Chrome, ColorResult } from "@uiw/react-color";

const SkillsEdit = () => {
    const [resume, setResume] = useState<Resume>();
    const [skill, setSkill] = useState<Skill>({ title: '', description: '', percentage: 0, color: '' });

    useEffect(() => {
        getResume().then(r => {
            setResume(r);
        });
    }, []);

    const handleItemChanged = (index: number, item: Skill) => {
        if (resume !== undefined) {
            const oResume = {
                ...resume,
                skills: resume?.skills.map((s, i) => {
                    var returnValue = {...s};
                
                    if (i === index) {
                        returnValue = item;
                    }
                
                    return returnValue;
                })
            }

            setResume(oResume);
        }
    }

    const handleItemDeleted = (index: number) => {
        if (resume !== undefined) {
            const oResume = {
                ...resume,
                skills: resume?.skills.filter((s, i) => i !== index)
            }

            setResume(oResume);
        }
    }
        
    const handleResumeSave = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        if (resume !== undefined) {
            postResume(resume).then(b => {
                setTimeout(() => getResume().then((r) => setResume(r)), 1000);                
            });
        }
    }

    const handleTitleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (skill !== undefined) {
            const ns: Skill = { ...skill, title: e.target.value };
            setSkill(ns);
        }        
    };

    const handleDescriptionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (skill !== undefined) {
            const ns: Skill = { ...skill, description: e.target.value };
            setSkill(ns);
        }        
    };

    const handlePercentageChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (skill !== undefined) {
            const ns: Skill = { ...skill, percentage: parseInt(e.target.value) };
            setSkill(ns);
        }        
    };
    
    const handleColorChanged = (e: ColorResult) => {
        if (skill !== undefined) {
            const ns: Skill = { ...skill, color: e.hex };
            setSkill(ns);
        }        
    };
    
    const handleSkillAdded = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        if (resume !== undefined && skill !== undefined) {
            const nr: Resume = {
                ...resume,
                skills: [...resume.skills, skill]
            };

            setSkill({ title: '', description: '', percentage: 0, color: '' });
            setResume(nr);
        }
    };
    
    return (
        <Paper>
            <Box sx={{ padding: '48px' }}>
                <Typography variant="h5">Skills</Typography>
                <Stack>
                    {resume?.skills?.map((s, i) => <SkillItemEdit index={i} skill={s} onItemChanged={handleItemChanged} onItemDeleted={handleItemDeleted} />)}
                    <Box sx={{ padding: '24px', marginTop: '24px', borderTop: '2px solid #ddd' }}>
                        <Stack direction="row" sx={{ padding: '18px 0 18px 0' }}>
                            <Box>
                                <FormControl>
                                    <Typography variant="body1">Title</Typography>
                                    <TextField
                                        id="title"
                                        variant="outlined"
                                        size="small"
                                        onChange={handleTitleChanged}
                                        value={skill?.title}
                                        sx = {{ width: '160px' }}
                                    ></TextField>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl>
                                    <Typography variant="body1">Description</Typography>
                                    <TextField
                                        id="from"
                                        variant="outlined"
                                        size="small"
                                        onChange={handleDescriptionChanged}
                                        value={skill?.description}
                                        sx = {{ width: '360px' }}
                                    ></TextField>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl>
                                    <Typography variant="body1">Percentage</Typography>
                                    <TextField
                                        id="to"
                                        variant="outlined"
                                        size="small"
                                        onChange={handlePercentageChanged}
                                        value={skill?.percentage}
                                        sx = {{ width: '120px' }}
                                    ></TextField>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl>
                                    <Typography variant="body1">Color</Typography>
                                        <Chrome 
                                            style={{ marginLeft: 20 }}
                                            color={skill?.color}
                                            onChange={handleColorChanged}
                                        />
                                </FormControl>
                            </Box>
                        </Stack>
                        <Stack>
                            <Button 
                                onClick={handleSkillAdded}
                                variant="contained"
                                href="#"
                                color="success"
                                sx={{ width: '120px' }}
                            >
                                Add Skill
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
                <Box sx={{ padding: '24px', marginTop: '24px', borderTop: '2px solid #ddd' }}>
                    <Button 
                        onClick={handleResumeSave}
                        variant="contained"
                        href="#"
                        color="success"
                        sx={{ width: '180px' }}
                    >
                        Save Resume
                    </Button>
                </Box>
            </Box>        
        </Paper>
    );
}

export default SkillsEdit;
